import React from 'react'
class Footer extends React.Component {
  render () {
    return (
      <footer className="p-5 pt-6 mt-3">
        <p><small className="is-size-7">2021 Alkamind Consulting Inc. All Rights Reserved.</small></p>
      </footer>
    )
  }
}
export default Footer
